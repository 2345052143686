import React, { useState } from "react";
import { Item } from "../Item/Item";
import { useFetchMarvel } from "../../hooks/useFetchMarvel";
import "./styles.scss";

export const ItemsList = () => {
  const [nameHero, setNameHero] = useState("Iron man");
  const [nameinput, setNameInput] = useState("Iron man");
  let collaborators = useFetchMarvel(`collaborators/${encodeURI(nameHero)}`);
  let characters = useFetchMarvel(`characters/${encodeURI(nameHero)}`);
  // let collaborators = [];
  // let characters = [];

  function activateFetch(e) {
    e.preventDefault();
    setNameHero(nameinput);
  }

  function handleNameChange(e) {
    console.log(e.target.value);
    setNameInput(e.target.value);
  }

  return (
    <>
      <form>
        <input
          type="text"
          name="superHero"
          placeholder="Iron man"
          onChange={handleNameChange}
        />
        <button className="buttonConsult" onClick={activateFetch}>
          Consult Superhero
        </button>
      </form>
      <div className="containerItemLists">
        <div>
          <p className="listTitle">List Editors</p>
          <div className="containerList">
            {collaborators.data?.editors.map((editor) => {
              return <Item key={editor} type="editor" name={editor} />;
            })}
          </div>
        </div>
        <div>
          <p className="listTitle">List Writers</p>
          <div className="containerList">
            {collaborators.data?.writers.map((writer) => {
              return <Item key={writer} type="writer" name={writer} />;
            })}
          </div>
        </div>
        <div>
          <p className="listTitle">List Colorists</p>
          <div className="containerList">
            {collaborators.data?.colorist.map((colorist) => {
              return <Item key={colorist} type="writer" name={colorist} />;
            })}
          </div>
        </div>
        <div>
          <p className="listTitle">Collaboration with other Characters</p>
          <div className="containerList">
            {characters.data?.characters.map((superHero) => {
              return (
                <Item
                  key={superHero.character}
                  type="collaboration"
                  name={superHero.character}
                  text={superHero.comics}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
