import React from "react";
import defaultImage from "../../assets/profile.png";
import "./styles.scss";

export const Item = ({ type, name, text = null, image = defaultImage }) => {
  const typeClass = `itemContainer__label itemContainer__label--${type}`;

  return (
    <div className="itemContainer">
      <div className="itemContainer__img">
        <img src={image} alt="default" />
      </div>
      <div className="itemContainer__body">
        <p className="itemContainer__body--header">{name}</p>
        <p className="itemContainer__body--text">
          {text?.map((comic, index) => (
            <label key={index}>{comic}</label>
          ))}
        </p>
      </div>
      <div className={typeClass}>
        <p>{type}</p>
      </div>
    </div>
  );
};
