import { useState, useEffect } from "react";

export const useFetchMarvel = (query) => {
  const URL = `https://marvel-4d6ytbdwza-uc.a.run.app/marvel/${query}`;
  const [data, setData] = useState({ loading: true, error: null, data: null });

  useEffect(() => {
    fetch(URL)
      .then((response) => response.json())
      .then((info) => {
        setData({
          loading: false,
          error: null,
          data: info.data,
        });
      })
      .catch((err) => ({ loading: false, error: err, data: null }));
  }, [URL]);

  return data;
};
